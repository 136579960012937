/* eslint-disable no-unused-vars */
import React from "react";
import { useParams } from "react-router-dom";
import { products } from "../utils/constants";
import "../assets/css/Product.css";

const Product = () => {
  const { id } = useParams();
  const productId = parseInt(id, 10); // Convert the id from string to integer
  const product = products.find((product) => product.id === productId);

  if (!product) {
    return <div>Product not found</div>;
  }

  const hasValidExtraSection =
    product.extraSection && product.extraSection.title.trim() !== "";
  const hasValidBullets =
    hasValidExtraSection &&
    product.extraSection.bullets?.some(
      (bullet) => bullet.title.trim() !== "" || bullet.desc.trim() !== ""
    );
  const hasExtraImages =
    hasValidExtraSection && product.extraSection.extraSecImg?.length > 0;

  return (
    <div className="product-page">
      <div className="product-hero">
        <h2>{product.heading}</h2>
        <strong>{product.subheading}</strong>
        <p>{product.description}</p>
        <img src={product.photos.mainImg} alt="Main product" />
      </div>
      <div className="product-desc">
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img src={product.photos.screenshots[0]} alt="Screenshot 1" />
          <img src={product.photos.screenshots[1]} alt="Screenshot 1" />
        </div> */}
        <div className="desc-container">
          <h3>Product Description</h3>
          {product.details?.length > 0 ? (
            product.details.map((detail, index) => (
              <div
                className="each-desc-div"
                // style={{
                //   flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                // }}
              >
                {detail.img && <img src={detail.img} alt="Screenshot 1" />}
                <span>
                  <strong>{detail.title}</strong>
                  <p key={index}>{detail.content}</p>
                </span>
              </div>
            ))
          ) : (
            <p>No details available</p>
          )}
        </div>
      </div>
      <div className="product-desc feature-div">
        <div className="desc-container feature">
          <h3>Key Features</h3>
          {product.features?.length > 0 ? (
            product.features.map((feature, index) => (
              <p key={index} style={{ display: "list-item", marginLeft:"20px" }}>
                <strong>{feature.title}</strong> {feature.desc}
              </p>
            ))
          ) : (
            <p>No features available</p>
          )}
        </div>
        <img src={product.photos.screenshots[2]} alt="Screenshot 2" />
      </div>
      {/* <div className="product-ss">
        <img src={product.photos.screenshots[2]} alt="Screenshot 3" />
        <img src={product.photos.screenshots[3]} alt="Screenshot 4" />
      </div> */}
      {hasValidExtraSection && hasValidBullets && (
        <div className="product-extra">
          <h3>{product.extraSection.title}</h3>
          <div className="product-extra-div">
            <ul>
              {product.extraSection.bullets.map((bullet, index) =>
                bullet.title.trim() !== "" ||
                (bullet.subBullets && bullet.subBullets.length > 0) ? (
                  <ul key={index}>
                    <li>
                      <h4>{bullet.title}</h4>
                      <div className="product-extra-div">
                        <div>
                          {bullet.subBullets.map((subBullet, subIndex) =>
                            subBullet.title.trim() !== "" ||
                            subBullet.desc.trim() !== "" ? (
                              <li key={subIndex} style={{ fontSize: "18px" }}>
                                <strong style={{ fontWeight: "500" }}>
                                  {subBullet.title}
                                </strong>{" "}
                                {subBullet.desc || ""}
                              </li>
                            ) : null
                          )}
                        </div>
                        {bullet.img && (
                          <img
                            key={index}
                            src={bullet.img}
                            alt={`extra-img-${index}`}
                          />
                        )}
                      </div>
                    </li>
                  </ul>
                ) : null
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
import React from "react";
import Slider from "react-slick";
import "../assets/css/Affiliation.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  DPIIT_cred,
  DYNALOG_cred,
  ENDUREAIR_cred,
  GOV_cred,
  IDEX_cred,
  IITD_cred,
  IITDIIC_cred,
  IITH_cred,
  IITT_cred,
  MSME_cred,
  NAVSTAR_cred,
  NAVY_cred,
  SMARTLEAVEN_cred,
  STARTUP_cred,
  WESEE_cred,
} from "../assets/img/affiliations";

const images = [
  DPIIT_cred,
  DYNALOG_cred,
  ENDUREAIR_cred,
  GOV_cred,
  IDEX_cred,
  IITD_cred,
  IITDIIC_cred,
  IITH_cred,
  IITT_cred,
  MSME_cred,
  NAVSTAR_cred,
  NAVY_cred,
  SMARTLEAVEN_cred,
  STARTUP_cred,
  WESEE_cred,
];

const Affiliation = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000, // Speed of scrolling
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for continuous scrolling
    cssEase: "linear", // Linear easing for continuous scrolling
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Mobile screen width
        settings: {
          slidesToShow: 2, // Show 2 slides at once on mobile
        },
      },
    ],
  };

  return (
    <div className="main-affiliation">
      <div className="affiliation-text">
        Trusted By Most Prominent Organizations
      </div>
      <div className="affiliation-container">
        <div className="affiliation-slider">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="slider-item">
                <img src={image} alt={`Affiliation ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Affiliation;
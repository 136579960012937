import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import Affiliation from "./components/Affiliation";
import Products from "./components/Products";
import TargetMarket from "./components/TargetMarket";
import WhyUs from "./components/WhyUs";
import Achivements from "./components/Achivements";
import Contact from "./components/Contact";
import FooterPage from "./components/FooterPage";
import Product from "./components/Product"; // Assuming this is the detailed product view component
import AboutUs from "./components/AboutUs"; // Assuming this is the detailed product view component
import Careers from "./components/Careers"; // Assuming this is the detailed product view component
import { mouse } from "./assets/img";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Banner />
                <Affiliation />
                <Products />
                <TargetMarket />
                <WhyUs />
                <Achivements />
                <Contact />
                <div className="mouse">
                  <a href="/#">
                    <img src={mouse} alt="Mouse GIF" />
                  </a>
                </div>
              </>
            }
          />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
        <FooterPage />
      </Router>
    </div>
  );
}

export default App;
import defexpo from "../assets/img/achievements/defexpo.jpg";
import delhiexpo from "../assets/img/achievements/delhiexpo.jpg";
import forward from "../assets/img/achievements/forward.jpg";
import isro_immersion from "../assets/img/achievements/isro_immersion.jpg";
import idex7 from "../assets/img/achievements/idex7.jpg";
import iit_tih from "../assets/img/achievements/iit_tih.jpg";
// import idex11 from "../assets/img/achievements/idex11.jpg";
import weartrack1 from "../assets/img/products/weartrack1.jpg";
import weartrack2 from "../assets/img/products/weartrack2.jpg";
import weartrack3 from "../assets/img/products/weartrack3.jpg";
import weartrack4 from "../assets/img/products/weartrack4.jpg";
import weartrack_main from "../assets/img/products/weartrack_main.jpg";
import aibat1 from "../assets/img/products/aibat1.jpg";
import aibat2 from "../assets/img/products/aibat2.jpg";
import aibat3 from "../assets/img/products/aibat3.jpg";
import aibat4 from "../assets/img/products/aibat4.jpg";
import aibat5 from "../assets/img/products/aibat5.jpg";
import aibat_main from "../assets/img/products/aibat_main.jpg";
import indisonic1 from "../assets/img/products/indisonic5.jpg";
import indisonic2 from "../assets/img/products/indisonic2.jpg";
import indisonic3 from "../assets/img/products/indisonic1.jpg";
// import indisonic4 from "../assets/img/products/indisonic4.jpg";
import indi_e1 from "../assets/img/products/indi_e1.png";
import indi_e2 from "../assets/img/products/indi_e2.png";
import indi_e3 from "../assets/img/products/indi_e3.png";
import indi_e4 from "../assets/img/products/indi_e4.png";
import indi_e5 from "../assets/img/products/indi_e5.png";
import indisonic_main from "../assets/img/products/indisonic_main.jpg";

export const products = [
  {
    id: 1,
    photos: {
      mainImg: weartrack_main,
      screenshots: [weartrack3, weartrack2, weartrack1, weartrack4],
    },
    heading: "WearTrak",
    subheading: "Dive into Safety, Not the Sea",
    description:
      "Transforming marine maintenance with virtual sensors for hassle-free monitoring of outboard shaft marine bearings and enhanced crew protection.",
    learnMoreLink: "#/product/1",
    details: [
      {
        img: weartrack3,
        title: "The Need for Technology",
        content:
          "Marine bearings face constant challenges from corrosion and external factors, leading to frequent and unpredictable wear. Traditionally, assessing this wear has been a demanding task involving divers manually measuring it with a poker gauge underwater. Due to operational limitations and the harsh marine environment, placing sensors directly on submerged outboard shaft bearings is impractical.",
      },
      {
        img: weartrack2,
        title: "WearTrak",
        content:
          "Our product WearTrak addresses this challenge head-on. By harnessing advanced virtual sensor technology, we've eliminated the need for physical sensors to be mounted on the outboard bearings themselves, sparing them from exposure to corrosive seawater and other harsh elements. Instead, our sensors are securely installed inside the ship, where they can safely and accurately monitor bearing wear from a distance. This approach not only simplifies the monitoring process but also enhances safety and reliability by reducing the need for divers to perform manual measurements in challenging conditions",
      },
      {
        title: "Customer Benefit",
        content:
          "This approach not only simplifies the monitoring process but also enhances safety and reliability by reducing reliance on divers. Ultimately, WearTrak enables the Naval as well as merchant ship to maintain operational readiness and extend the lifespan of critical marine components while ensuring personnel safety.",
      },
    ],
    features: [
      {
        title: "Virtual Sensor Technology:",
        desc: "Eliminates need for physical mounting of sensors on submerged outboard shaft bearings.",
      },
      {
        title: "Safe & Reliable Sensor Positioning:",
        desc: "Sensors are securely installed inside the ship, where they can safely and accurately monitor bearing wear down from a distance.",
      },
      {
        title: "Marine Industry Standard Wear Assessment:",
        desc: "Can measure wear down as high as 12 mm.",
      },
      {
        title: "High Speed Data Acquisition & Processing:",
        desc: "Equipped with a 24-bit ADC resolution that supports  102.4 kS/s/channel",
      },
      {
        title: "Marine & Military Grade Components:",
        desc: "All components comply with MIL STD 461 E/F and JS 5555",
      },
    ],
    extraSection: {
      title: "",
      extraSecImg: [],
      bullets: [
        {
          title: "",
          subBullets: [
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
          ],
        },
        {
          title: "",
          subBullets: [
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
          ],
        },
      ],
    },
  },

  {
    id: 2,
    photos: {
      mainImg: aibat_main,
      screenshots: [aibat_main, aibat2, aibat_main, aibat4],
    },
    heading: "AI-BAT",
    subheading:"Transforming Sonar for Enhanced Situational Awareness",
    description:
      "Harness the power of AI-driven adaptive noise cancellation to enhance underwater operations, improve threat detection, and ensure unmatched situational awareness—no matter the conditions",
    learnMoreLink: "#/product/2",
    details: [
      {
        img: aibat5,
        title: "The Need for Technology",
        content:
          "The Indian Navy requires effective sonar systems with AI capabilities to overcome challenges from ambient, self-generated, and platform noise, which can hinder underwater operations affected by environmental factors like temperature, salinity, and traffic density.",
      },
      {
        img: aibat1,
        title: "AI-BAT",
        content:
          "Guardinger Technologies’  AI-Based Adaptive Noise Cancellation system for Autonomous Underwater Vehicles (AUVs) and ship-borne applications, offers automated denoising, target classification, AI-driven real-time analysis, multi-processing for efficient data management, and intuitive visualization, all while operating unattended.",
      },
      {
        img: aibat3,
        title: "Modes of Operation",
        content:
          "The system features two key modes of operation. In Offline Mode, it processes historical data batches efficiently and autonomously, ensuring thorough analysis without real-time constraints. In Online Mode, the system performs real-time analysis and provides decision support during active operations, adapting dynamically to changing conditions. Both modes leverage AI-driven capabilities to optimize performance and ensure seamless, accurate noise cancellation and target classification for Autonomous Underwater Vehicles (AUVs) and ship-borne applications.",
      },
      {
        title: "Customer Benefit",
        content:
          "It aims at enhancing maritime surveillance, improving underwater threat detection, and facilitating precise navigation.",
      },
    ],
    features: [
      {
        title: "Automated Denoising (Low to High SNR)",
        desc: "",
      },
      {
        title: "Target Classification",
        desc: "",
      },
      {
        title: "Smart Analysis of Targets/Threats",
        desc: "",
      },
      // {
      //   title: "Data Visualization:",
      //   desc: "Presents acoustic data in intuitive graphical formats for easy interpretation: Includes displays of Joint Time-Frequency Analysis (JTFA) and waveform data.",
      // },
      // {
      //   title: "Unattended Operation:",
      //   desc: "Executes and manages processing tasks autonomously, minimizing manual intervention.",
      // },
    ],
    extraSection: {
      title: "",
      extraSecImg: [],
      bullets: [
        {
          title: "",
          subBullets: [
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
            {
              title: "",
              desc: "",
            },
          ],
        },
      ],
    },
  },

  {
    id: 3,
    photos: {
      mainImg: indisonic_main,
      screenshots: [indisonic1, indisonic_main, indisonic_main, indisonic2],
    },
    heading: "Indisonic Pro GT 200",
    subheading: "Acquire Anywhere Transmit Everywhere",
    description:
      "An Advanced Underwater Acoustic Monitoring System for Research and Defense",
    learnMoreLink: "#/product/3",
    details: [
      {
        img: indisonic1,
        title: "Indisonic Pro GT 200",
        content:
          "This state-of-the-art electronic payload system includes a robust, hydrophone array with high speed data acquisition board, and a compact edge computing device equipped with sophisticated processing algorithms.",
      },
      {
        img: indisonic3,
        title: "What Sets It Apart",
        content:
          "What truly sets it apart is its satellite-based data transmission, enabling seamless communication from any location on Earth. Ideal for platforms like sonobuoys and unmanned underwater vehicles like gliders, this system effectively monitors underwater activities for both scientific research and surveillance purposes.",
      },
    ],
    features: [
      {
        title: "Real Time Monitoring of Underwater Target",
        desc: "",
      },
      {
        title: "Direction of Arrival Estimation (DOA) using DIFAR",
        desc: "",
      },
      {
        title: "Doppler Compensation",
        desc: "",
      },
      {
        title: "Signal Denoising",
        desc: "",
      },
      {
        title: "Target Classification",
        desc: "",
      },
      {
        title: "Relevant (signal of interest) & irrelevant signal segregation supporting long duration data acquisition and processing",
        desc: "",
      },
    ],
    extraSection: {
      title: "System Specifications",
      extraSecImg: [indi_e1, indi_e2, indi_e3, indi_e4],
      bullets: [
        {
          title: "Hydrophones",
          img: indi_e4,
          subBullets: [
            {
              title: "Number of Hydrophones: ",
              desc: "3 (Customisable)",
            },
            {
              title: "Hydrophone Receiving Sensitivity: ",
              desc: "-153 dB re 1V/μPa with 40dB gain",
            },
            {
              title: "Usable Frequency:",
              desc: "1 Hz to 80 kHz",
            },
            {
              title: "Operating depth:",
              desc: "1000m",
            },
          ],
        },
        {
          title: "Data Acquisition Board",
          img: indi_e3,
          subBullets: [
            {
              title: "Programmable rate:",
              desc: "10 kS/s - 51.2 kS/s ( Customizable to 100 kHz)",
            },
            {
              title: "Number of channels:",
              desc: "4",
            },
          ],
        },
        {
          title: "Compact Edge Computer",
          img: indi_e2,
          subBullets: [
            {
              title: "GPU:",
              desc: "384-core NVIDIA Volta™ GPU with 48 Tensor Cores",
            },
            {
              title: "CPU:",
              desc: "6-core NVIDIA Carmel ARM®v8.2 64-bit CPU 6MB L2 + 4MB L3",
            },
            {
              title: "Memory:",
              desc: "8 GB 128-bit LPDDR4x 51.2GB/s",
            },
            {
              title: "Storage:",
              desc: "16 GB eMMC 5.1( Customizable)",
            },
          ],
        },
        {
          title: "Satellite Communication Module",
          img: indi_e1,
          subBullets: [
            {
              title: "Satellite Communication",
              desc: "",
            },
            {
              title: "Up to 22 Kbps Transmit / Up to 88 Kbps Receive",
              desc: "",
            },
          ],
        },
        {
          title: "Software",
          img: indi_e5,
          subBullets: [
            {
              title: "Enables data acquisition",
              desc: "",
            },
            {
              title: "Signal Processing:",
              desc: "FFT/ Spectrogram/LOFAR/DEMON",
            },
            {
              title: "Data Analysis, Visualization and Interpretation",
              desc: "",
            },
            {
              title: "GUI based controls for data transmission",
              desc: "",
            },
          ],
        },
      ],
    },
  },
];

export const targetMarket = [
  "Maritime & Underwater Research",
  "Aerospace Technologies ",
  "Geospatial Technologies",
  "Defence Technologies",
];

export const whyUsKey = [
  {
    id: 1,
    title: "Cutting-Edge Technology",
    tagline:
      "We leverage the latest advancements in AI to deliver innovative solutions.",
  },
  {
    id: 2,
    title: "Domain Expertise",
    tagline:
      "Our team of experts has deep knowledge and experience in the targeted sectors.",
  },
  {
    id: 3,
    title: "Customized Solutions",
    tagline:
      "We understand the specific requirements of each sector and tailor our solutions accordingly.",
  },
];

export const achivements = [
  {
    id: 3,
    imgUrl: defexpo,
    info: "Maharashtra MSME Defence Expo, Pune",
    year: "24th -26th Feb, 2024",
  },
  {
    id: 7,
    imgUrl: iit_tih,
    info: "Start Demo Day, Technology Innovation Hub, IITTNiF,  Tirupati",
    year: "25th Sept 2024",
  },
  {
    id: 4,
    imgUrl: delhiexpo,
    info: "National Seminar on Innovation in Underwater Navigation, IIT Delhi",
    year: "14th June, 2024",
  },
  {
    id: 6,
    imgUrl: isro_immersion,
    info: "ISRO Immersion Challenge, Hyderabad",
    year: "3rd August, 2024",
  },
  {
    id: 5,
    imgUrl: forward,
    info: "Forward Area Tour, J&K",
    year: "August, 2023",
  },
  {
    id: 1,
    imgUrl: idex7,
    info: "Winners: iDEX DIO Naval Innovation & Indigenisation Organisation (DISC) Sprint 7",
    year: "",
  },
];

export const getInTouch = [
  {
    id: 1,
    heading: "Useful Links",
    links: [
      {
        linkTitle: "YouTube",
        linkURL: "http://www.youtube.com/@GuardingerTechnologies",
      },
      { linkTitle: "LinkedIn" },
    ],
  },
  {
    id: 2,
    heading: "Company",
    links: [
      { linkTitle: "About Us", linkURL: "https://www.guardinger.in/#/about" },
      { linkTitle: "Contact Us", linkURL: "mailto:info@guarginger.in" },
      {
        linkTitle: "Map Location",
        linkURL: "https://maps.app.goo.gl/FH62WxD2pdAsCUvg8",
      },
    ],
  },
  {
    id: 3,
    heading: "Legal",
    links: [
      { linkTitle: "Privacy Policy" },
      { linkTitle: "Terms and Conditions" },
    ],
  },
];

export const aboutUs = [
  {
    title: "About Us",
    content:
      "Guardinger is a high-tech hardware based start-up dedicated to creating intelligent, sustainable products for mission-critical applications in the maritime industry, defense, and space sectors. Guardinger is powered by a highly skilled team with expertise in advanced robotics, IoT, AI/ML, signal processing, and computer vision. The company also has individuals with strong business acumen and exceptional execution capabilities. Guardinger's solutions are designed to ensure uninterrupted business operations and continuous uptime health.",
  },
  {
    title: "Our Mission",
    content:
      "To pioneer innovation in intelligent, sustainable hardware solutions for mission-critical applications in the maritime industry, defense, and space sectors. Guardinger is committed to safeguarding global interests by leveraging advanced robotics, IoT, AI/ML, signal processing, and computer vision to ensure uninterrupted business operations and continuous uptime health.",
  },
  {
    title: "Our Vision",
    content:
      "Guardinger envisions a world where technological excellence meets unwavering dedication to mission-critical operations. We strive to be the trusted global leader in high-tech hardware, setting new industry standards for sustainability, resilience, and intelligence. Our vision is to enable a safer, more secure, and sustainable future for all, powered by a diverse, highly skilled team dedicated to pushing the boundaries of innovation and execution capabilities.",
  },
];
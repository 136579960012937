import React from 'react'
import "../assets/css/WhyUs.css";
import {whyUs} from "../assets/img";
import {whyUsKey} from "../utils/constants";

export default function WhyUs() {
  return (
    <div className="whyus-container">
      <div className="whyus">
        <div className="whyus-text">
          <h1>WHY GUARDINGER</h1>
          <h2>Pioneering AI-driven Innovations</h2>
          <p>Our dedication to excellence in advanced technology and AI/ML solutions distinguishes us as the preferred partner for critical sectors. Our expert team is committed to delivering cutting-edge technology that meets the highest standards of quality and performance.</p>
        </div>
        <div className="why-image">
          <img src={whyUs} alt="" />
        </div>
      </div>
      <div className="whyus-mobile">
        <div className="whyus-mobile-text">
          <h1>WHY GUARDINGER</h1>
          <h2>Pioneering AI-driven Innovations</h2>
          <p>Our dedication to excellence in advanced technology and AI/ML solutions distinguishes us as the preferred partner for critical sectors. Our expert team is committed to delivering cutting-edge technology that meets the highest standards of quality and performance.</p>
        </div>
      </div>
      <div className="why-label">
        {whyUsKey.map((why)=> (
            <svg width="362" height="160" viewBox="0 0 362 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="10.5742" y="8.57418" width="349.852" height="149.852" stroke="#3B63ED" stroke-width="3.14836"/>
            <rect width="352" height="149" fill="white"/>
            <foreignObject width="97%" height="93%" className="why-content">
                <h1>{why.title}</h1>
                <p>{why.tagline}</p>       
            </foreignObject>
            </svg> 
        ))}
      </div>
    </div>
  )
}
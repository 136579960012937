import React from "react";
import { achivements } from "../utils/constants";
import "../assets/css/Achivements.css";

export default function Achivements() {
  return (
    <div className="ach-container">
      <h1>Achievements </h1>
      <h2>
        Showcasing Our Achievements, Milestones, and Notable Representations
      </h2>
      <div className="ach-image-grid">
        {achivements.map((imageInfo) => (
          <svg
            width="394"
            height="342"
            viewBox="0 0 394 342"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="20"
              y="17"
              width="373"
              height="324"
              stroke="#3B63ED"
              stroke-width="2"
            />
            <rect width="375" height="326" fill="white" />
            <foreignObject width="97%" height="93%" className="why-content">
              <div className="ach-images">
                <img src={imageInfo.imgUrl} alt="" />
                <p>{imageInfo.info}</p>
                <span>{imageInfo.year}</span>
              </div>
            </foreignObject>
          </svg>
        ))}
      </div>
    </div>
  );
}
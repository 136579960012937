/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/img/logo.svg";
import logo_opc from "../assets/img/logo_opc.svg";
import logo_opc_light from "../assets/img/logo_opc_light.svg";
import "../assets/css/Navbar.css";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    closeMobileMenu();
  };

  const scrollToSection = (sectionId) => {
    closeMobileMenu();
    navigate("/#"); // Navigate to root
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0); // Ensures that the scrolling happens after the navigation
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Function to check if the screen width is less than or equal to 520px
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia("(max-width: 520px)").matches);
    };

    // Check screen size on component mount
    handleResize();

    // Add an event listener for screen resizing
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div
        ref={menuRef}
        className={`navbar-menu ${isMobileMenuOpen ? "active" : ""}`}
      >
        <ul>
          <li>
            <a href="/#" onClick={closeMobileMenu}>
              Home
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/about")}>About Us</a>
          </li>
          <li>
            <a onClick={() => scrollToSection("products")}>Products</a>
          </li>
          <li>
            <a onClick={() => scrollToSection("collaborate")}>Collaborate</a>
          </li>
          <li>
            <a onClick={() => handleNavigation("/careers")}>Careers</a>
          </li>
          <li>
            {/* <a onClick={() => handleNavigation("/careers")}>
              Guardinger Technologies(OPC)
            </a> */}
            {/* <img src={logo_opc} alt="Logo" /> */}
            <img src={isSmallScreen ? logo_opc_light : logo_opc} alt="Logo" />
          </li>
        </ul>
      </div>
      <button className="navbar-toggle" onClick={toggleMobileMenu}>
        ☰
      </button>
    </nav>
  );
};

export default NavBar;
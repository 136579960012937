import React from "react";
import "../assets/css/AboutUs.css";
import {
  DPIIT_cred,
  ENDUREAIR_cred,
  IDEX_cred,
  IITD_cred,
  IITH_cred,
  IITT_cred,
  MSME_cred,
  NAVSTAR_cred,
  NAVY_cred,
  SMARTLEAVEN_cred,
  WESEE_cred,
} from "../assets/img/affiliations";

export default function AboutUs() {
  return (
    <div className="about-main">
      <div className="about-us">
        <h3>About Us</h3>
        <p>
          Guardinger pioneers advanced robotics, sensor tech, and data analytics
          to create innovative products for underwater situational awareness and
          asset management across civilian, industrial, and defense sectors. Our
          team includes Ph.Ds, Scientists & Domain Experts from top Indian and European institutions
          alongside experienced engineers, specializing in:
        </p>
        <ul>
          <li>Enhanced underwater situational awareness systems</li>
          <li>Predictive maintenance solutions for high-value assets</li>
          <li>
            Data-driven optimizations for asset reliability: We've successfully
            implemented these solutions in key industries like oil & gas,
            cement, and defense, extending asset lifecycles and improving
            operational readiness.
          </li>
        </ul>
        <p>
          Guardinger's approach blends rigorous R&D with strategic execution,
          ensuring reliable design, development, and deployment. Expanding into
          geospatial solutions, we continue to prioritize reliability,
          performance, and customer satisfaction in every project.
        </p>
      </div>
      <div className="vision">
        <h3>Our Vision</h3>
        <p>
          To design, develop & deliver products and solutions for underwater,
          space and geospatial applications, harnessing the power of technology
          & innovation.
        </p>
      </div>
      <div className="mission">
        <h3>Our Mission</h3>
        <p>
          To leverage expertise in Robotics, IoT, Sensor technologies, Advanced
          electronics systems & Onboard computing, GeoSpatial data analytics and
          Artificial intelligence to innovate and deliver high-value products
          for mission-critical applications, ensuring customer satisfaction
          through continuous innovation. This mission is planned to be
          implemented in the realm of following domains:
        </p>
        <ul>
          <li>Underwater Missions</li>
          <li>Space Missions</li>
          <li>
            Reliability, Availability, Maintainability and Dependability of
            high-valued critical assets- industrial and defence through
            predictive & prescriptive condition based analytics
          </li>
          <li>
            Geospatial data mining for better life problems and sustainable
            development goals
          </li>
        </ul>
      </div>
      <div className="values">
        <h3>Values</h3>
        <ul>
          <li>
            <strong>Innovation: </strong>Pioneering groundbreaking technologies
            through rigorous research and development
          </li>
          <li>
            <strong>Efficiency and Effectiveness: </strong>Focused and
            disciplined approach to deliver
          </li>
          <li>
            <strong>Customer-Centricity: </strong>Understanding and addressing
            the unique needs of each customer
          </li>
          <li>
            <strong>Collaboration: </strong>Working together with partners and
            stakeholders to achieve shared goals
          </li>
          <li>
            <strong>Integrity: </strong>Upholding the highest ethical standards
            in all aspects of our business.
          </li>
        </ul>
      </div>
      <div className="affiliations">
        <h3>Affiliations</h3>
        <div className="institute-affiliations">
          <h3>Institutional Affiliations</h3>
          <p>
            <strong>Institutional Affiliations:</strong>IIT Hyderabad, IIT
            Tirupati, and IIIT Delhi for different areas of expertise.
          </p>
          <div className="affiliation-grid-img">
            <img src={IITH_cred} alt="" />
            <img src={IITT_cred} alt="" />
            <img src={IITD_cred} alt="" />
          </div>
        </div>
        <div className="gov-affiliations">
          <h3>Government Affiliations</h3>
          <p>
            We are  supported by the Government of India's Startup India
            initiative and the DPIIT (Department for Promotion of Industry and
            Internal Trade) and MSME. Additionally, we actively engage
            with iDEX on various defense projects, furthering our commitment to
            innovation and national defense. Currently doing multiple projects
            with the Indian Navy & WESEE, Ministry of Defence (MoD).
          </p>
          <div className="affiliation-grid-img">
            <img src={NAVY_cred} alt="" />
            <img src={WESEE_cred} alt="" />
            <img src={DPIIT_cred} alt="" />
            <img src={IDEX_cred} alt="" />
            <img src={MSME_cred} alt="" />
          </div>
        </div>
        <div className="institute-affiliations">
          <h3>Industrial Collaborations</h3>
          <p>
            SmartLeaven Electronics Systems Pune, EndurAir for
            Drones, Navstar for military communications
          </p>
          <div className="affiliation-grid-img">
            <img src={SMARTLEAVEN_cred} alt="" />
            <img src={ENDUREAIR_cred} alt="" />
            <img src={NAVSTAR_cred} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

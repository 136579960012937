import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import "../assets/css/Banner.css"; // Import the custom CSS
import {
  underwater_hero,
  condition_hero,
  situation_hero,
  geo_hero,
} from "../assets/img";

const Banner = () => {
  return (
    <div className="banner-container" id="/">
      <Carousel
        autoPlay
        interval={10000}
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const indicatorStyle = {
            width: "30px",
            height: "2px",
            backgroundColor: isSelected ? "#fff" : "#ccc",
            margin: "100px 4px",
            cursor: "pointer",
            display: "inline-block",
          };
          return (
            <li
              style={indicatorStyle}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        <div className="carousel-slide">
          <img src={underwater_hero} alt="Underwater" />
          <div className="caption">
            <h1>Underwater Technologies</h1>
            <p>Diving into underwater innovation with advanced signal processing and sustainable monitoring in maritime industries</p>
          </div>
        </div>
        <div className="carousel-slide">
          <img src={condition_hero} alt="Condition" />
          <div className="caption">
            <h1>Predictive Maintenance of Mission Critical Systems</h1>
            <p>Custom predictive maintenance hardware & software for defense & Industrial assets: Ships, Tanks & , Aircrafts & Critical Industrial Machinery</p>
          </div>
        </div>
        <div className="carousel-slide">
          <img src={situation_hero} alt="Situation" />
          <div className="caption">
            <h1>Situational Awareness and Command Control Softwares</h1>
            <p>Real-time sensor data integration directly within the user interface, enabling real-time monitoring, data visualization, and interaction</p>
          </div>
        </div>
        <div className="carousel-slide">
          <img src={geo_hero} alt="Geospatial" />
          <div className="caption">
            <h1>Geospatial Technology</h1>
            <p>Integrating spatial data for informed decision-making in complex landscapes. Harnessing GIS for optimised infrastructure planning and natural resource utilisation. Remote sensing applications for real-time monitoring of biodiversity and ecosystem</p>
          </div>
        </div>
      </Carousel>
      {/* <div className="tagline-hero">
      Innovation Beyond Limits:  Specialised Products for  Defence & Industry
      </div> */}
    </div>
  );
};

export default Banner;
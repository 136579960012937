import React from "react";
import "../assets/css/Careers.css"; // Optional: Create a CSS file for styling

const Careers = () => {
  return (
    <div className="careers-container">
      <h1>Careers</h1>
      <p>
        Thank you for your interest in joining our team. At this time, we do not
        have any open positions available. Please check back periodically for
        updates, as our career opportunities may change.
      </p>
    </div>
  );
};

export default Careers;
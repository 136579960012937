import React, { useState } from "react";
import "../assets/css/Contact.css";
import emailjs from "emailjs-com";

export default function Contact() {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Use the form element directly
    emailjs
      .sendForm(
        "service_vq12slp", // Service ID from EmailJS
        "template_klej9kc", // Template ID from EmailJS
        e.target, // Form element
        "duwvNpB4xS5b65L5R" // User ID from EmailJS
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSent(true); // Mark the message as sent
        },
        (error) => {
          console.log(error.text);
        }
      );

    // Clear form fields after submission
    setFormData({ name: "", email: "", message: "" });
  };

  return (
    <div className="contact-container" id="collaborate">
      <div className="contact-info">
        <h1>Contact Us</h1>
        <h2>Guardinger Technologies Pvt Ltd</h2>
        <p>info@guardinger.in</p>
        <p>Office No. 07, 3rd Floor, Sarovar-2,</p>
        <p>Bavdhan, Pune, Maharashtra - 411021</p>
      </div>
      <div className="contact-form">
        {/* Wrap form elements inside a form tag */}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="name">Full Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group message">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button className="contact-btn" type="submit">
            Send
          </button>
        </form>
        {isSent && <p>Thank you for your message. We'll get back to you soon!</p>}
      </div>
    </div>
  );
}
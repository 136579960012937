import React from "react";
import "../assets/css/FooterPage.css";
import logo from "../assets/img/logo_light.svg";
import { getInTouch } from "../utils/constants";

const FooterPage = () => {
  return (
    <div className="footer-container">
      <div className="footer-main-container">
        <div className="company-footer">
          <img src={logo} alt="Guardinger Technologies" srcset="" />
          <h2>Elevate unlimited possibilities with our smart innovations</h2>
        </div>
        <div className="get-in-touch">
          {getInTouch.map((section) => (
            <div className="links" key={section.id}>
              <h4>{section.heading}</h4>
              <ul>
                {section.links.map((link, index) => (
                  <li key={index}>
                    <a href={link.linkURL}>{link.linkTitle}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <footer className="footer">
        <p>
          &copy; {new Date().getFullYear()} Guardinger Technologies. All Rights
          Reserved.
        </p>
      </footer>
    </div>
  );
};

export default FooterPage;
import React from "react";
import "../assets/css/Products.css";
import { products } from "../utils/constants";
import { useNavigate } from "react-router-dom";

export default function Products() {
  const navigate = useNavigate();

  const handleRectClick = (id) => {
    // Replace `:id` with the actual product ID you want to navigate to
    navigate(`/product/${id}`);
  };

  return (
    <div id="products">
      <div className="products">
        <h1>Our Products</h1>
        <p>
          Innovative Solutions for Defense, Industry, Aerospace, and Underwater:
          Tailored Technology for Critical Operations
        </p>
      </div>
      <div className="products-grid">
        {products.map((product) => (
          <div key={product.id}>
            <svg
              width="322"
              height="379"
              viewBox="0 0 322 379"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="16.0195"
                y="17.3164"
                width="303.817"
                height="359.7"
                stroke="#3B63ED"
                stroke-width="3.14836"
              />
              <rect width="306.983" height="363.128" fill="white" />
              <foreignObject width="308" height="280" className="item-content">
                <h1>{product.heading}</h1>
                <p>{product.description}</p>
              </foreignObject>
              <foreignObject
                y="290"
                width="200"
                height="60"
                className="item-content"
              >
                <button onClick={() => handleRectClick(product.id)}>
                  Learn More
                </button>
              </foreignObject>
            </svg>
            <div></div>
          </div>
        ))}
      </div>
    </div>
  );
}